<template>
  <div class="lg:w-4/5 w-11/12 mx-auto mt-12">
    <!-- <h1 class="text-xl font-bold">Add Device</h1> -->
    <div
      v-if="processing"
      class="
        box-style
        flex
        my-6
        p-5
        gap-x-3
        items-center
        border-b border-gray-400
      "
    >
      <div class="text-center mx-auto">Adding. Please Wait....</div>
    </div>
    
    <div
      v-if="error"
      class="
        box-style
        flex
        my-6
        p-5
        gap-x-3
        items-center
        border-b border-red-600 bg-red-200 text-red-500
      "
    >
      <div class="text-center mx-auto">An Error Occured. Please try again</div>
    </div>

    <div
      v-if="showBussinessDetails"
      class="box-style flex p-5 gap-x-3 items-center border-b border-gray-400"
    >
      <div class="flex-1 leading-tight">
        <h2 class="text-lg font-semibold m-0 capitalize">Device Added</h2>
        <p class="my-3 text-xs leading-relaxed">
          This device is now tied to your account
        </p>
        <div class="overflow-x-auto relative bg-gray-200 rounded-lg">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
          >
            <tbody>
              <tr class="border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="
                    py-4
                    px-6
                    font-medium
                    text-gray-900
                    whitespace-nowrap
                    dark:text-white
                  "
                >
                  Business Name -
                  {{ businessData.name || "N/A" }}
                </th>
              </tr>
              <tr class="border-b dark:bg-gray-800 dark:border-gray-700">
                <th
                  scope="row"
                  class="
                    py-4
                    px-6
                    font-medium
                    text-gray-900
                    whitespace-nowrap
                    dark:text-white
                  "
                >
                  BusinessID - {{ businessData.user_id || "N/A" }}
                </th>
              </tr>
              <tr class="dark:bg-gray-800">
                <th
                  scope="row"
                  class="
                    py-4
                    px-6
                    font-medium
                    text-gray-900
                    whitespace-nowrap
                    dark:text-white
                  "
                >
                  Email -
                  {{ businessData.email || "N/A" }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
} from "../../browser-db-config/localStorage";

export default {
  data() {
    return {
      businessData: GET_USER_BUSINESS_DATA(),
      processing: true,
      showBussinessDetails: false,
      error: false,
    };
  },
  methods: {
    async generateDeviceID() {
    return this.$fpjs.getVisitorData({
        extendedResult: true
    }).then((fingerprint) => {
        // console.log("Generated Fingerprint: ", fingerprint.visitorId);
        return fingerprint.visitorId
    })
    },
    async sendDeviceID(deviceId) {
      return fetch(
        "https://webhook.site/6aed19ca-2ede-44d7-a2b7-e1669f2c3ada",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            businessName: this.businessData.businessName,
            businessId: this.businessData.user_id,
            businessEmail: this.businessData.email,
            fingerprint: deviceId,
          }),
        }
      );
    },
  },
  created() {
    this.generateDeviceID()
      .then((deviceId) => {
        return this.sendDeviceID(deviceId);
      })
      .then(() => {
        this.processing = false;
        this.showBussinessDetails = true;
      })
      .catch((err) => {
        console.error('Error Sending Device ID: ', err)
        this.error = true;
        this.showBussinessDetails = false;
        this.processing = false;
      });
  },
};
</script>

<style lang="postcss" scoped>
.normal-label {
  @apply border border-gray-800 text-black;
}
.success-label {
  @apply border bg-green-600 text-white;
}
.warning-label {
  @apply border border-yellow-600 text-yellow-700;
}
.error-label {
  @apply border border-red-600 text-red-700;
}
.history-title {
  background: #effaff;
}
.box-style {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
  border-radius: 15px;
}
</style>